.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.mainContainer {
  display: flex;
  justify-content: center;
  width: 100%;
}

.dataContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;

}

@media (min-width:768px) {
  .dataContainer {
    width:768px;
  }
}

.bio {
  text-align: center;
  color: gray;
  margin-top: 16px;
}


.header {
  display: flex;
  align-items: center;
  height: 65px;
  background: linear-gradient(90deg, #14e6cd 1.18%, #153ed2 100%);
  color: white;
  padding: 20px;
}

.headerImage {
  margin-right: 12px;
}

.headerTitle {
  font-size: 16px;
  margin-right: auto;
}

.headerMenu {

}

.headerMenu > span {
  font-size: 12px;
  padding:0 10px;
}


.postContentForm {
  margin: 1em;
}

textarea {
  border: 1px solid #cccccc;
  border-radius: 6px;
  padding:10px;
}

.postContentButton {
  display: block;
}

.postedContent {
  padding: 1em;
  margin: 1em 0;
  border: 1px solid #cccccc;
  border-radius: 6px;
}

.postedAddressAndDate {
  font-size: 12px;
}

.postedAddressAndDate>span {
  display: block;
}

.postedMessage {
  padding: 1em;
  margin: 1em 0;
}

.postedMessage p {
  margin: 0;
  padding: 0;
}

.postedMessageBottom {
  display: flex;
  align-items: center;
  font-size: 12px;
}

.likedPostButton {
  margin-right: auto;
}

.sendGiftButton {
  margin-left: 0.8em;
}

.deleteLikeButton {
  color: #E1344C;
  font-size: 18px;
  margin:0 0.5em;
}

.postLikeButton {
  color: #E1344C;
  font-size: 18px;
  margin:0 0.5em;
}

.selectOrderBy{
  display: flex;
  justify-content:flex-end;
  margin:0;
  padding:0;
  font-size: 14px;
}

.selectOrderBy > span{
  padding:0 5px;
}

.loader,
.loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}
.loader {
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(255,0,0, 0.2);
  border-right: 1.1em solid rgba(255,0,0, 0.2);
  border-bottom: 1.1em solid rgba(255,0,0, 0.2);
  border-left: 1.1em solid #00fbff;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.overlay{
  position: fixed;
  left: 0; top: 0;
  width: 100%;
  height: 100%;
  background: rgba(254, 255, 255, 0.8);
  z-index: 2147483647;
  display: flex;
  justify-content: center;
  align-items: center;
}
.errorMessage{
  font-size:12px;
  color:red;
  padding:0 10px;
}
.postedContentList{
  margin: 0 1em;
}
